import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/system";
import { containerStyle, TroubleshootGuideTypes } from "constants/index";

import useAnnotationApi from "hooks/useAnnotationApi.hook";
import useTrialApi from "hooks/useTrialApi.hook";

import { ROUTES } from "constants/routes.constants";
import { buildLocalUrl } from "utils/url.utils";
import { IngestStatus } from "services/annotation.service";

import { CardStatus } from "components/Card";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import Loading from "components/Loading";

import { EventCards } from "./Components/EventCards.component";
import { TimerController } from "./Components/Timer.controller";

import { useResultStore } from "./result.store";
import ParticipantLogFormModal from "./Components/ParticipantLogFormModal";
import { FieldConfig } from "utils/form.utils";
import { useLeftNavigationStore } from "stores/navigation.store";
import { iconsLink, ResultActions } from "constants/index";
import {
  rpcEventCommandType,
  CreateRpcEventParams,
  rpcType,
} from "services/fleet.service";
import useDevicesApi from "hooks/useDevicesApi.hook";
import Information from "./Components/InformationBlock.controller";
import { Metrics } from "./Components/MetricsContainer.controller";
import TimerButtonContainer from "./Components/TimerButtonContainer.controller";
import ActionButtonContainer from "./Components/ActionButton.component";
import { MetricResults } from "components/MetricResults/MetricResults.component";
import useGuideStore from "components/TroubleShootGuide/guide.store";

const Result = () => {
  const { trialId, trialMemberId, annotationId } = useParams();
  const [searchParams] = useSearchParams();
  const serialNumbers = searchParams.get("serial-numbers")?.split(",");
  const ingestId = searchParams.get("ingestId");
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const initHeader = useHeaderStore((state) => state.initialize);
  const setLoading = useResultStore((state) => state.setLoading);
  const setAnnotation = useResultStore((state) => state.setAnnotation);
  const currentEvent = useResultStore((state) => state.currentEvent);
  const participant = useResultStore((state) => state.participant);
  const startTimer = useResultStore((state) => state.startTimer);
  const events = useResultStore((state) => state.events);
  const setParticipant = useResultStore((state) => state.setParticipant);
  const ingest = useResultStore((state) => state.ingest);
  const resetResultStore = useResultStore((state) => state.reset);

  const setCurrentGuideId = useGuideStore((state) => state.setCurrentGuideId);
  const steps = useHeaderStore((state) => state.steps);
  const addNavigationState = useLeftNavigationStore(
    (state) => state.addNavigationState
  );
  const switchNavigationState = useLeftNavigationStore(
    (state) => state.switchNavigationState
  );

  const setIngest = useResultStore((state) => state.setIngest);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [actionLabel, setActionLabel] = useState<string>("");
  const [canRedirect, setCanRedirect] = useState<boolean>(false);
  const [stepLabel, setStepLabel] = useState<string>("");
  const [isParticipantLogged, setIsParticipantLog] = useState<boolean>(false);

  const trialQueries = useTrialApi({ trialId: trialId || "" });
  const deviceQueries = useDevicesApi({
    serialNumbers: [],
  });
  const annotationQueries = useAnnotationApi({
    annotationId: annotationId,
    ingestId: ingestId || "",
  });

  const setAllowLogoAction = useLeftNavigationStore(
    (state) => state.setAllowLogoAction
  );

  const handleOpenModal = (_: string, actionTitle: string) => {
    if (!isParticipantLogged) {
      setIsModalOpen(true);
      setStepLabel(actionTitle);
      setActionLabel("navigateProgressBar");
    }
  };

  useEffect(() => {
    setAllowLogoAction(false);
    startTimer();
    return () => {
      switchNavigationState("default");
      resetResultStore();
    };
  }, []);

  const getOverallStatus = () => {
    const event = events.find((e) => e.name === currentEvent?.name);
    if (!event) return ProgressBarStatus.IN_PROGRESS;
    switch (event.status) {
      case ProgressBarStatus.SUCCESS:
      case ProgressBarStatus.FAIL:
      case ProgressBarStatus.WARNING:
        return event.status;
      default:
        return ProgressBarStatus.IN_PROGRESS;
    }
  };

  useEffect(() => {
    addNavigationState("experiment", [
      {
        icon: iconsLink.exit_sample,
        label: "trial.noze.result.event.results.actions.button.purge",
        onClick: () => {
          resetResultStore();
          navigatePurge(ROUTES.ROOT);
        },
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.white.light,
      },
    ]);
    switchNavigationState("experiment");
    initHeader(
      {
        mainTitle: {
          label: "header.title.label.study",
          text: trialQueries.trial.data?.name || "",
        },
        subTitleItems: [
          {
            label: "header.title.subItem.label.participant",
            text: participant?.pui || "",
          },
          {
            label: "header.title.subItem.label.device",
            text: serialNumbers?.join(", ") || "",
          },
        ],
        showMenu: true,
        showBackButton: false,
      } as InitHeaderOptions,

      {
        activeStep: 4,
        allowAction: false,
        onClick: isParticipantLogged ? undefined : handleOpenModal,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipants",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANTS,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantDevices",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANT_DEVICES,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantExperiment",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantExperimentResults",
            status: getOverallStatus(),
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
        {
          key: "trialMemberId",
          value: trialMemberId || "",
        },
        {
          key: "annotationId",
          value: annotationId || "",
        },
      ]
    );
    setCurrentGuideId(TroubleshootGuideTypes.RESULTS);
  }, [trialQueries.trial.data, ingest, participant, events]);

  useEffect(() => {
    if (!trialQueries.participants.data) return;

    const participant = trialQueries.participants.data?.data.find(
      (member: any) => member.uuid === trialMemberId
    );
    if (!participant) return;
    setParticipant(participant);
    setLoading(false);
  }, [trialQueries.participants.data]);

  useEffect(() => {
    if (!annotationQueries.annotation.data) return;
    if (!annotationQueries.ingest.data) return;
    setAnnotation(annotationQueries.annotation.data);
    setIngest(annotationQueries.ingest.data);
  }, [
    annotationQueries.annotation.data,
    annotationQueries.ingest.data?.status,
  ]);

  useEffect(() => {
    if (annotationQueries.annotation.data) {
      const metadata = annotationQueries.annotation.data.metadata;
      if (metadata && Object.keys(metadata).includes("participantLog")) {
        setIsParticipantLog(true);
        setIsModalOpen(false);
      }
    }
  }, [annotationQueries.annotation.data]);

  const handleRedirect = () => setCanRedirect(true);

  const createActionFunction = (actionLabel: string) => {
    return () => {
      setActionLabel(actionLabel);
      if (!isModalOpen) setIsModalOpen(true);
    };
  };

  const deviceAction = createActionFunction("navigateDevices");
  const purgeAction = createActionFunction("navigatePurge");
  const exitAction = createActionFunction("navigateExit");
  const progressBarAction = createActionFunction("progressBarAction");

  const navigatePurge = (actionUrl?: string) => {
    const referrerUrl = actionUrl
      ? actionUrl
      : `${location.pathname}${location?.search}`;
    if (!serialNumbers?.length) return;
    const url = buildLocalUrl({
      path: ROUTES.DEVICES_PURGE,
      params: {
        trialId,
        serialNumber: serialNumbers[0],
      },
      query: {
        referrerUrl,
      },
    });
    resetResultStore();
    navigate(url);
  };
  const navigateExit = () => {
    resetResultStore();
    navigate(ROUTES.TRIALS_MANAGEMENT);
  };
  const navigateExitViaPurge = () => {
    navigatePurge(ROUTES.TRIALS_MANAGEMENT);
  };
  const navigateProgressBar = () => {
    const selectedStep = steps.find((step) => step.label === stepLabel);
    if (selectedStep && selectedStep.link) {
      const url = buildLocalUrl({
        path: selectedStep.link,
      });
      resetResultStore();
      navigate(url);
    }
  };
  const navigateProgressBarViaPurge = () => {
    const selectedStep = steps.find((step) => step.label === stepLabel);
    if (selectedStep && selectedStep.link) {
      const url = buildLocalUrl({
        path: selectedStep.link,
      });
      navigatePurge(url);
    }
  };
  const navigateDevices = () => {
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
      params: {
        trialId,
        trialMemberId,
      },
    });
    resetResultStore();
    navigate(url);
  };
  const navigateDevicesViaPurge = () => {
    const url = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
      params: {
        trialId,
        trialMemberId,
      },
    });
    navigatePurge(url);
  };

  const createRpcEvent = async (payload: CreateRpcEventParams) => {
    if (rpcEventCommandType.COMMANDS in payload) {
      const { serialNumber, wait, commands } = payload;
      const data = commands?.map((command) => ({
        ...command,
        rpcType: rpcType.TWO,
      }));
      await deviceQueries.createRpcEvents.mutateAsync({
        wait: wait || 0,
        data,
        serialNumber,
      });
    } else {
      const { method, params, serialNumber } = payload;
      await deviceQueries.createRpcEvents.mutateAsync([
        {
          method,
          params,
          rpcType: rpcType.TWO,
          serialNumber,
        },
      ]);
    }
  };

  const handleGenerateRPC = async (
    status: CardStatus,
    payload:
      | {
          method: string;
          params: { [key: string]: string };
        }
      | {
          wait: number;
          commands: Array<{
            method: string;
            params: { [key: string]: string };
          }>;
        }
  ) => {
    if (!serialNumbers || !serialNumbers.length) return;
    setLoading(true);

    try {
      if (rpcEventCommandType.COMMANDS in payload) {
        const { wait, commands } = payload;
        await createRpcEvent({
          wait,
          commands,
          serialNumber: serialNumbers[0],
        });
      } else {
        const { method, params } = payload;
        await createRpcEvent({
          method,
          params,
          serialNumber: serialNumbers[0],
        });
      }
    } catch (err) {
      console.error("Failed to generate RPC:", err);
    } finally {
      setLoading(false);
    }
  };

  const getIllustrations = () => {
    if (!ingest) return [];
    if (!currentEvent?.content?.assets) return [];
    if (!ingest.status) return currentEvent.content.assets.success;
    switch (ingest.status) {
      case IngestStatus.PENDING:
      case IngestStatus.COMPLETE:
        return currentEvent.content.assets.success || [];
      case IngestStatus.FAILED:
        return currentEvent.content.assets.fail || [];
      case IngestStatus.TERMINATED:
        return currentEvent.content.assets.warning || [];
      default:
        return currentEvent.content.assets.pending || [];
    }
  };

  const illustrations = getIllustrations();

  const performAction = (actionLabel: ResultActions) => {
    const actions: Record<ResultActions, () => void | false> = {
      navigateDevices: navigateDevices,
      navigateDevicesViaPurge: navigateDevicesViaPurge,
      navigatePurge: navigatePurge,
      navigateExit: navigateExit,
      navigateProgressBar: navigateProgressBar,
      navigateProgressBarViaPurge: navigateProgressBarViaPurge,
      navigateExitViaPurge: navigateExitViaPurge,
    };

    const actionFunction = actions[actionLabel];
    if (actionFunction) {
      actionFunction();
      const action = currentEvent?.actions?.find(
        (action) => action.action === actionLabel
      );
      if (action?.rpc && serialNumbers) {
        const rpcEvents = action.rpc.map((v) => {
          return { ...v, rpcType: rpcType.TWO, serialNumber: serialNumbers[0] };
        });
        deviceQueries.createRpcEvents.mutateAsync(rpcEvents);
      }
    }
  };
  useEffect(() => {
    if (canRedirect || isParticipantLogged) {
      performAction(actionLabel as ResultActions);
    }
  }, [canRedirect, isParticipantLogged, isModalOpen]);

  const actions = {
    navigateDevices: deviceAction,
    navigatePurge: purgeAction,
    navigateExit: exitAction,
    navigateProgressBar: progressBarAction,
    navigateExitViaPurge: navigateExitViaPurge,
    navigateDevicesViaPurge: navigateDevicesViaPurge,
    generateRPC: handleGenerateRPC,
  };

  const checkLoading = () => {
    if (!ingest) return true;
    if (!currentEvent) return true;
    if (!annotationQueries.annotation.data) return true;
    if (!trialQueries.trial.data) return true;
    return false;
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const schemaData: FieldConfig[] =
    trialQueries.trial.data?.configuration.data.forms.participantLog.fields;
  const initialValues = trialQueries.trial.isFetched
    ? schemaData.reduce((acc, { name }) => ({ ...acc, [name]: "" }), {})
    : {};

  const getProgressCardStatus = () => {
    if (!events?.length) return CardStatus.PENDING;
    const event = events.find((e) => e.name === currentEvent?.name);
    if (!event) return CardStatus.IN_PROGRESS;
    return event.status;
  };

  const progressCardStatus = getProgressCardStatus();

  return (
    <>
      <TimerController actions={actions} />
      {checkLoading() ? (
        <Loading />
      ) : (
        <>
          <Information illustrations={illustrations} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-around"
            flexDirection="column"
            sx={{
              ...containerStyle,
              marginBottom: "2rem",
            }}
          >
            <Grid item width={"100%"}>
              <Grid container alignItems={"center"}>
                {progressCardStatus === CardStatus.PENDING ||
                progressCardStatus === CardStatus.IN_PROGRESS ? (
                  <>
                    <Grid item sm={8}>
                      <Metrics />
                    </Grid>
                    <Grid item sm={4}>
                      <TimerButtonContainer actions={actions} />
                    </Grid>
                  </>
                ) : (
                  <Grid item flexGrow={1}>
                    <Grid
                      container
                      display={"flex"}
                      spacing={5}
                      flexDirection={"row-reverse"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <ActionButtonContainer actions={actions} />
                      <MetricResults result={ingest.result} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container flexDirection="row" justifyContent="flex-end">
            {currentEvent && progressCardStatus === CardStatus.SUCCESS && (
              <EventCards event={currentEvent} options={{ ingest }} />
            )}
          </Grid>
        </>
      )}
      <ParticipantLogFormModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleClose}
        handleRedirect={handleRedirect}
        schemaData={schemaData}
        initialValues={initialValues}
        isParticipantLogged={isParticipantLogged}
      />
    </>
  );
};

export default Result;
