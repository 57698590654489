import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import {
  AnnotationActions,
  AnnotationStatus,
  EventValidateRules,
  Rule,
} from "services/annotation.service";
import { CardStatus } from "components/Card";

import moment, { Moment } from "moment";
import { ProgressBarStatus } from "stores/header.store";
import { ICreateRpcEvent } from "services/fleet.service";
import { timeFormat } from "constants/index";
import { checkRules, validateRules } from "utils/device.utils";
import { Validation } from "stores/experiment.store";

export enum PurgeStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  WARNING = "WARNING",
}

export interface PurgeEvent {
  status: CardStatus;
  name: string;
  createdAt: Moment;
}

export interface CardConfiguration {
  name: string;
  label: string;
  status: CardStatus;
  options: {
    showIcon: boolean;
    background: string;
  };
}

export interface ContentAsset {
  url: string;
  alt: string;
  timeout?: number;
}

export interface ContentConfiguration {
  title: string;
  action: {
    fail: string;
    pending: string;
    success: string;
    warning: string;
    progress: string;
  };
  assets: {
    pending: ContentAsset[];
    success: ContentAsset[];
    warning: ContentAsset[];
    fail: ContentAsset[];
    progress: ContentAsset[];
  };
  description: {
    fail: string;
    pending: string;
    success: string;
    warning: string;
    progress: string;
  };
}

export interface ValidationRules {
  params: string[];
  settlingTime: number;
  timeout: number;
  component?: string;
  orientation?: string;
  rules: EventValidateRules[];
  actions?: Record<string, any>[];
}

export interface PurgeTimerActions {
  time: number;
  actions: string[];
  statuses: CardStatus[];
}

export interface PurgeTimer {
  timeout: number;
  direction: string;
  actions: PurgeTimerActions[];
  isVisible?: boolean;
}

export interface PurgeEventConfiguration {
  name: string;
  rpc: ICreateRpcEvent[];
  progress: { cards: CardConfiguration[] };
  content: ContentConfiguration;
  showInProgressSteps: boolean;
  isVisible: boolean;
  status: AnnotationStatus;
  timer: PurgeTimer;
  validate: ValidationRules | null;
  actions: AnnotationActions[];
  stopOnFail: boolean;
}

export interface PurgeConfiguration {
  events: PurgeEventConfiguration[];
}

export interface Verdict {
  verdict: boolean;
  max?: number;
  min?: number;
  value?: number | string;
}
export interface RulesCheckResults {
  [key: string]: Verdict;
}

interface PurgeStore {
  serialNumbers: string[];
  setSerialNumbers: (serialNumbers: string[]) => void;
  status: ProgressBarStatus;
  setStatus: (status: ProgressBarStatus) => void;
  purgeConfiguration?: PurgeConfiguration | null;
  setPurgeConfiguration: (configuration: PurgeConfiguration) => void;
  currentEvent: PurgeEventConfiguration | null;
  setCurrentEvent: (event: PurgeEventConfiguration | null) => void;
  nextEvent: PurgeEventConfiguration | null;
  setNextEvent: (event: PurgeEventConfiguration) => void;
  device: any | null;
  rules: Rule[] | null;
  setRules: (rules: Rule[]) => void;
  setDevice: (device: any) => void;
  ruleChecksStatus: boolean;
  setRuleChecksStatus: (status: boolean) => void;
  rulesCheckResults: RulesCheckResults | null;
  setRulesCheckResults: (rulesCheckResults: RulesCheckResults) => void;
  purgeEvent: PurgeEvent | null;
  setPurgeEventStatus: (status: CardStatus) => void;
  setPurgeEvent: (purgeEvent: PurgeEvent) => void;
  purgeEvents: PurgeEvent[];
  timeSinceEvent: number; // in seconds
  setTimeSinceEvent: () => void;
  trial: any;
  setTrial: (trial: any) => void;
  reset: () => void;
  validate: (metric: any) => void;
  validation: Validation;
}

const init = {
  serialNumbers: [],
  status: ProgressBarStatus.PENDING,
  purgeConfiguration: null,
  currentEvent: null,
  nextEvent: null,
  device: null,
  rules: [],
  ruleChecksStatus: true,
  rulesCheckResults: null,
  purgeEvent: null,
  purgeEvents: [],
  timeSinceEvent: 0,
  trial: null,
  validation: { verdict: false },
};

export const getNextEvent = (events: any, currentEvent: any = null) => {
  if (!events) return null;
  if (!currentEvent) {
    const _currentEvent = events[0];
    return {
      ..._currentEvent,
      timeout: Number(_currentEvent.timer?.timeout || 0),
    };
  }
  if (currentEvent.allowedEvents.length === 0) return null;
  const nextEvent = events.find(
    (item: any) =>
      currentEvent.allowedEvents[0].toLowerCase() === item.name.toLowerCase()
  );
  return {
    ...nextEvent,
    timeout: Number(nextEvent.timer?.timeout || 0),
  };
};

export const usePurgeStore = create<PurgeStore>()(
  persist(
    (set) => ({
      ...init,
      setSerialNumbers: (serialNumbers: string[]) => set({ serialNumbers }),
      setStatus: (status: ProgressBarStatus) => set({ status }),
      setPurgeConfiguration: (purgeConfiguration: PurgeConfiguration) =>
        set({ purgeConfiguration }),
      setDevice: (device: any) =>
        set(() => {
          return {
            device,
            purgeConfiguration: device?.purge,
            rules: device?.rules,
          };
        }),
      setRules: (rules: Rule[]) => set({ rules }),
      setPurgeEvent: (purgeEvent: PurgeEvent) =>
        set((prevState) => {
          const utcTime = moment
            .utc()
            .format(timeFormat.UTC) as unknown as Moment;
          const newState = {
            purgeEvents: [
              ...prevState.purgeEvents,
              { ...purgeEvent, createdAt: utcTime },
            ],
            purgeEvent: { ...purgeEvent, createdAt: utcTime },
          };
          return newState;
        }),
      setPurgeEventStatus: (status: CardStatus) =>
        set(({ purgeEvent, purgeEvents }) => {
          if (!purgeEvent) return { purgeEvent: null, purgeEvents: [] };
          if (!purgeEvents.length) return { purgeEvent: null, purgeEvents: [] };
          const _purgeEvents = purgeEvents.map((event) => {
            if (event.name === purgeEvent?.name) {
              return { ...event, status };
            }
            return event;
          });
          return {
            purgeEvent: { ...purgeEvent, status },
            purgeEvents: _purgeEvents,
          };
        }),
      setRuleChecksStatus: (status: boolean) =>
        set({ ruleChecksStatus: status }),
      setRulesCheckResults: (rulesCheckResults: RulesCheckResults) =>
        set({ rulesCheckResults }),
      validate: (metric: any) =>
        set(({ currentEvent, device }) => {
          if (!device) return {};
          const rulesCheckResults = checkRules(device.rules, metric);
          if (!currentEvent) return { rulesCheckResults };
          if (!currentEvent.validate) return { rulesCheckResults };
          const validation = validateRules(
            currentEvent.validate,
            rulesCheckResults
          );
          return { rulesCheckResults, validation };
        }),
      setTimeSinceEvent: () =>
        set(({ purgeEvent }) => {
          if (!purgeEvent) return { timeSinceEvent: 0 };
          const now = moment();
          const then = moment(purgeEvent.createdAt);
          const diff = moment.duration(now.diff(then));
          const seconds = diff.asSeconds();
          return { timeSinceEvent: seconds };
        }),
      setTrial: (trial: any) => set({ trial }),
      setCurrentEvent: (event: PurgeEventConfiguration | null) =>
        set({ currentEvent: event }),
      setNextEvent: (event: PurgeEventConfiguration) =>
        set({ nextEvent: event }),
      reset: () => {
        set(init);
      },
    }),
    {
      name: "purge-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
