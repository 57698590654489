import React from "react";
import { CardMedia, Grid, Typography, AccordionActions } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button, { ButtonColor } from "components/Button";
import {
  StyleAccordion,
  StyleAccordionDetails,
  StyleAccordionSummary,
  StyleSubTitle,
} from "./styles";
import { Guide } from "services/configuration.service";
import {
  TroubleshootGuideActions,
  TroubleshootGuideMediaTypes,
} from "constants/index";
import { useTranslation } from "react-i18next";
import Spacer from "components/Spacer";
import { useTheme } from "@mui/system";
import { useParams, useSearchParams } from "react-router-dom";

interface GuideItemProps {
  guide: Guide;
  guideIndex: number;
  actions: {
    [key: string]: (...args: any) => void;
  };
}

interface ActionButton {
  label: string;
  action: () => Promise<void>;
  disabled: boolean;
  isLoading: boolean;
  color: ButtonColor;
}

const TroubleShootGuideItem: React.FC<GuideItemProps> = ({
  guide,
  guideIndex,
  actions,
}) => {
  const { trialId, trialMemberId } = useParams();
  const [searchParams] = useSearchParams();
  const serialNumbers = searchParams.get("serial-numbers")?.split(",");

  const getActionButtons = (guideActions: any): ActionButton[] =>
    guideActions?.map(({ actions: subActions, label, color }: any) => {
      const isDisabled = subActions.some((subAction: {name:string,params: object} | string) => {
        const { name } =
          typeof subAction === "object" ? subAction : { name: subAction };
        return (
          (name === TroubleshootGuideActions.REDIRECT_TO_PURGE &&
            (!serialNumbers || !trialId)) ||
          (name === TroubleshootGuideActions.REDIRECT_TO_DEVICES &&
            (!trialMemberId || !trialId))
        );
      });

      const executeSubActions = async () => {
        await actions.closeDrawer();
        for (const subAction of subActions) {
          const { name, params } =
            typeof subAction === "object"
              ? subAction
              : { name: subAction, params: undefined };
          await actions[name]?.(params);
        }
      };

      return {
        action: executeSubActions,
        disabled: isDisabled,
        label,
        color: color as ButtonColor,
        isLoading: false,
      };
    }) ?? [];

  const { t } = useTranslation();
  const theme = useTheme();
  const actionsList = getActionButtons(guide?.actions);

  return (
    <StyleAccordion
      key={`childKey_${guide.title}_${guideIndex}`}
      defaultExpanded={true}
    >
      <StyleAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${guideIndex + 1}-content`}
        id={`panel${guideIndex + 1}-header`}
      >
        <StyleSubTitle>{guide?.title && t(guide.title)}</StyleSubTitle>
      </StyleAccordionSummary>
      <StyleAccordionDetails key={`subTitleKey${guideIndex + 1}`}>
        <Grid container gap={"1rem"}>
          <Grid item flexGrow={1}>
            {guide?.subTitle && (
              <Typography component={"div"}>
                {t(guide.subTitle)}
                <Spacer height="1rem" />
              </Typography>
            )}
            <Typography component={"div"}>
              {guide?.text && t(guide.text)}
            </Typography>
          </Grid>
          {guide?.media?.length &&
            guide.media.map((media, index) => (
              <Grid item key={`media${index + 1}`}>
                {media.type === TroubleshootGuideMediaTypes.IMAGE ? (
                  <img
                    key={`media${index + 1}-image`}
                    alt={t(media?.alt)}
                    src={media.url}
                  />
                ) : (
                  <CardMedia
                    key={`media${index + 1}-video`}
                    component="video"
                    image={media.url}
                    controls
                  />
                )}
              </Grid>
            ))}
        </Grid>
      </StyleAccordionDetails>
      {actionsList.length > 0 && (
        <AccordionActions sx={{ padding: "0.5rem 1rem" }}>
          {actionsList.map(
            ({ label, action, disabled, isLoading, color }, i) => (
              <Button
                key={`action-button-${label}-${i}`}
                size="small"
                onClick={action}
                disabled={disabled}
                isLoading={isLoading}
                color={color || ButtonColor.primary}
                sx={{
                  ...(disabled && {
                    border: "none",
                    backgroundColor: theme.palette.pending.light,
                  }),
                }}
              >
                {t(label)}
              </Button>
            )
          )}
        </AccordionActions>
      )}
    </StyleAccordion>
  );
};

export default TroubleShootGuideItem;
