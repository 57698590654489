import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import moment from "moment";

import {
  AnnotationConfiguration,
  AnnotationEvent,
  AnnotationStatus,
  Device,
} from "services/annotation.service";

import { Participant } from "services/cde.service";
import { checkRules, validateRules } from "utils/device.utils";
import { CardStatus } from "components/Card";
import { timeFormat } from "constants/index";
import { getAdjustedTime } from "services/time.services";

export interface ResultsCheck {
  [key: string]: boolean;
}

export interface ValidationResult {
  name: string;
  title: string;
  background: string;
  settlingTime: number;
  timeout: number;
  verdict: boolean;
  results?: any;
  telemetryType: string;
  isVisible?: boolean;
  controlsState?: boolean;
}
export interface Validation {
  verdict: boolean;
  results?: ValidationResult[];
}

interface ExperimentStore {
  serialNumbers: string[];
  setSerialNumbers: (serialNumbers: string[]) => void;
  status: AnnotationStatus;
  eventsConfiguration: AnnotationEvent[];
  setConfigurationEvents: (events: AnnotationEvent[]) => void;
  currentEvent: AnnotationEvent | undefined;
  nextEvent: AnnotationEvent | undefined;
  alignEvents: () => void;
  device: Device | undefined;
  setDevice: (device: any) => void;
  updateCurrentEventAction: (id: string, show: boolean) => void;
  annotationConfiguration?: AnnotationConfiguration | undefined;
  setAnnotationConfiguration: (
    annotationConfiguration: AnnotationConfiguration
  ) => void;
  resultsCheck: ResultsCheck;
  validation: Validation;
  validate: (metric: any) => void;
  experimentFrom: any;
  setExperimentForm: (experimentFrom: any) => void;
  participant: Participant | undefined;
  setParticipant: (participant: Participant) => void;
  annotation: any;
  setAnnotation: (annotation: any, status: CardStatus) => void;
  annotationEvent: any;
  addAnnotationEvent: (annotation: any, status: CardStatus) => void;
  annotationEvents: any[];
  updateEventStatus: (name: string, status: CardStatus) => void;
  timeSinceEvent: number; // in seconds
  setTimeSinceEvent: () => void;
  trial: any;
  setTrial: (trial: any) => void;
  timerStatus: boolean;
  startTimer: () => void;
  stopTimer: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  reset: () => void;
  failedStep: string;
  setFailedStep: (failedStep: string) => void;
  stopExperiment: boolean;
  setStopExperiment: (stopExperiment: boolean) => void;
  elapsedTime: number;
  setElapsedTime: (elapsedTime: number) => void;
}

const init = {
  serialNumbers: [],
  status: AnnotationStatus.PENDING,
  eventsConfiguration: [],
  currentEvent: undefined,
  nextEvent: undefined,
  device: undefined,
  annotationConfiguration: undefined,
  resultsCheck: {},
  validation: { verdict: false },
  experimentFrom: undefined,
  participant: undefined,
  annotation: undefined,
  annotationEvent: undefined,
  annotationEvents: [],
  timeSinceEvent: 0,
  trial: undefined,
  timerStatus: false,
  loading: false,
  failedStep: "",
  stopExperiment: false,
  elapsedTime: 0,
};

const getNextEvent = (events: any, currentEvent: any = undefined) => {
  if (!events) return undefined;
  if (!currentEvent) {
    const _currentEvent = events.find(
      (event: any) => event.name.toLowerCase() === "initialization"
    );
    return _currentEvent;
  }
  if (currentEvent.allowedEvents.length === 0) return undefined;
  const nextEvent = events.find(
    (item: any) =>
      currentEvent.allowedEvents[0].toLowerCase() === item.name.toLowerCase()
  );
  return nextEvent;
};

export const useExperimentStore = create<ExperimentStore>()(
  persist(
    (set, get) => ({
      ...init,
      setSerialNumbers: (serialNumbers: string[]) => set({ serialNumbers }),
      setConfigurationEvents: (eventsConfiguration: AnnotationEvent[]) =>
        set(() => {
          const currentEvent = getNextEvent(eventsConfiguration);
          const nextEvent = getNextEvent(eventsConfiguration, currentEvent);
          return { eventsConfiguration, currentEvent, nextEvent };
        }),
      alignEvents: () =>
        set(({ eventsConfiguration, currentEvent }) => {
          const newCurrentEvent = getNextEvent(
            eventsConfiguration,
            currentEvent
          );
          const nextEvent = getNextEvent(eventsConfiguration, newCurrentEvent);
          const status = newCurrentEvent.annotationStatus;
          return { currentEvent: newCurrentEvent, nextEvent, status };
        }),
      updateCurrentEventAction: (id: string, show: boolean) => {
        set(({ currentEvent }) => {
          if (!currentEvent) return { currentEvent };
          const updatedActions = currentEvent?.actions?.map((action) => {
            if (action.id === id) {
              return { ...action, show };
            }
            return action;
          });
          return {
            currentEvent: {
              ...currentEvent,
              actions: updatedActions,
            },
          };
        });
      },
      setDevice: (device: Device) => set({ device }),
      setAnnotationConfiguration: (
        annotationConfiguration: AnnotationConfiguration
      ) => set({ annotationConfiguration }),
      validate: (metric: any) =>
        set(({ currentEvent, annotationConfiguration, stopExperiment }) => {
          if (stopExperiment) return {};
          if (!annotationConfiguration) return {};
          const { device: deviceConfiguration } = annotationConfiguration.data;
          const resultsCheck = checkRules(deviceConfiguration.rules, metric);
          if (!currentEvent) return { resultsCheck };
          if (!currentEvent.validate) return { resultsCheck };
          const validation = validateRules(currentEvent.validate, resultsCheck);
          return { resultsCheck, validation };
        }),
      setExperimentForm: (experimentFrom: any) => set({ experimentFrom }),
      setParticipant: (participant: Participant) => set({ participant }),
      setAnnotation: (annotation: any, status: CardStatus) =>
        set(({ annotationEvents }) => {
          const { elapsedTime } = get();
          const adjustedTime = getAdjustedTime(elapsedTime);
          const utcTime = adjustedTime.format(timeFormat.UTC);
          const annotationEvent = annotation.events
            ? {
                ...annotation.events[0],
                createdAt: utcTime,
                status,
              }
            : [];
          return {
            annotation,
            annotationEvent: annotationEvent,
            annotationEvents: [...annotationEvents, annotationEvent],
          };
        }),
      addAnnotationEvent: (annotationEvent: any, status: CardStatus) =>
        set(({ annotationEvents }) => {
          const event = { ...annotationEvent, status };
          const newAnnotationsEvents = [...annotationEvents, event];
          return {
            annotationEvent: event,
            annotationEvents: newAnnotationsEvents,
          };
        }),
      updateEventStatus: (name: string, status: CardStatus) =>
        set(({ annotationEvents }) => {
          const event = annotationEvents.find((item) => item.name === name);
          if (!event) return { annotationEvents };
          if (event.status === status) return { annotationEvents };
          let annotationEvent = { ...event, status };
          const events = annotationEvents.map((event) => {
            if (event.name !== name) return event;
            return annotationEvent;
          });
          return { annotationEvents: events, annotationEvent };
        }),
      setTimeSinceEvent: () =>
        set(({ annotationEvent }) => {
          if (!annotationEvent) return { timeSinceEvent: 0 };
          const { elapsedTime } = get();
          const adjustedTime = getAdjustedTime(elapsedTime);
          const then = moment(annotationEvent.createdAt).utc();
          const diff = moment.duration(adjustedTime.diff(then));
          const seconds = diff.asSeconds();
          return { timeSinceEvent: seconds };
        }),
      setTrial: (trial: any) => set({ trial }),
      startTimer: () => set({ timerStatus: true }),
      stopTimer: () => set({ timerStatus: false }),
      setLoading: (loading: boolean) => set({ loading }),
      reset: () => set(() => init),
      setFailedStep: (failedStep: string) => set({ failedStep }),
      setStopExperiment: (stopExperiment: boolean) => set({ stopExperiment }),
      setElapsedTime: (elapsedTime: number) => set({ elapsedTime }),
    }),
    {
      name: "experiment-store",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
