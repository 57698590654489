import { ReactNode, useEffect } from "react";
import {
  Route,
  Navigate,
  useParams,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
  matchPath,
} from "react-router-dom";
import TrialsManagement from "pages/TrialsManagement";
import StudiesDashboard from "pages/StudiesDashboard";
import Devices from "pages/Devices";
import Experiment from "pages/Experiment";
import Result from "pages/Result";
import PurgeDevice from "pages/PurgeDevice";
import Participants from "pages/Participants/Participants.controller";
import { AuthenticationProvider } from "providers/authentication.provider";
import PageContainer from "layouts/PageContainer";
import { ROUTES } from "constants/routes.constants";
import StudyOverview from "./StudiesDashboard/StudyOverview";
import StudyParticipants from "./StudiesDashboard/StudyParticipants";
import StudyDevices from "./StudiesDashboard/StudyDevices";
import { ParticipantDashboard } from "./ParticipantDashboard/ParticipantDashboard";
import ErrorBoundaryWrapper from "components/errors/ErrorBoundaryWrapper";
import NotFound from "components/errors/NotFound";
import DashboardProtectedRoute from "./DashboardProtectedRoute";
import { usePurgeStore } from "./PurgeDevice/purge.store";
import { useResultStore } from "./Result/result.store";
import { ProgressBarStatus } from "stores/header.store";

interface RouteWrapperProps {
  element: ReactNode;
}

type RouteParams = {
  trialId?: string;
  pui?: string;
  annotationId?: string;
};

function RouteWrapper({ element }: RouteWrapperProps) {
  const params: RouteParams = useParams();
  const location = useLocation();
  const resetPurgeStore = usePurgeStore((state) => state.reset);
  const purgeStoreStatus = usePurgeStore((state) => state.status);
  const resetResultStore = useResultStore((state) => state.reset);
  const resultStoreEvents = useResultStore((state) => state.events);

  useEffect(() => {
    const isPurgePage = matchPath(
      { path: ROUTES.DEVICES_PURGE, end: true },
      location.pathname
    );
    const isResultPage = matchPath(
      { path: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS, end: true },
      location.pathname
    );
    if (!isPurgePage && purgeStoreStatus !== ProgressBarStatus.PENDING)
      resetPurgeStore();
    if (!isResultPage && resultStoreEvents.length) resetResultStore();
  }, [location]);

  return (
    <ErrorBoundaryWrapper>
      <PageContainer routeParams={params}>{element}</PageContainer>
    </ErrorBoundaryWrapper>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to={ROUTES.TRIALS_MANAGEMENT} />} />
      <Route
        path={ROUTES.TRIALS_MANAGEMENT}
        element={<RouteWrapper element={<TrialsManagement />} />}
      />
      <Route
        path={ROUTES.TRIAL_PARTICIPANT_DEVICES}
        element={<RouteWrapper element={<Devices />} />}
      />
      <Route
        path={ROUTES.TRIAL_PARTICIPANT_EXPERIMENT}
        element={<RouteWrapper element={<Experiment />} />}
      />
      <Route
        path={ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS}
        element={<RouteWrapper element={<Result />} />}
      />

      <Route
        path={ROUTES.TRIAL_PARTICIPANTS}
        element={<RouteWrapper element={<Participants />} />}
      />

      <Route
        path={ROUTES.TRIAL_PARTICIPANT_DETAILS}
        element={<RouteWrapper element={<TrialsManagement />} />}
      />
      <Route
        path={ROUTES.TRIAL_PARTICIPANT_DETAILS_SUMMARY}
        element={<RouteWrapper element={<TrialsManagement />} />}
      />
      <Route
        path={ROUTES.TRIAL_DETAILS}
        element={<RouteWrapper element={<TrialsManagement />} />}
      />
      <Route
        path={ROUTES.DEVICES_PURGE}
        element={<RouteWrapper element={<PurgeDevice />} />}
      />
      <Route
        path="/"
        element={<RouteWrapper element={<TrialsManagement />} />}
      />

      <Route element={<DashboardProtectedRoute />}>
        <Route
          path={ROUTES.STUDIES_DASHBOARD}
          element={<RouteWrapper element={<StudiesDashboard />} />}
        />
        <Route
          path={ROUTES.STUDIES_DASHBOARD_STUDY_OVERVIEW}
          element={<RouteWrapper element={<StudyOverview />} />}
        />
        <Route
          path={ROUTES.STUDIES_DASHBOARD_TRIAL_DETAILS}
          element={<RouteWrapper element={<StudiesDashboard />} />}
        />
        <Route
          path={ROUTES.STUDIES_DASHBOARD_STUDY_PARTICIPANTS}
          element={<RouteWrapper element={<StudyParticipants />} />}
        />
        <Route
          path={ROUTES.STUDIES_DASHBOARD_STUDY_DEVICES}
          element={<RouteWrapper element={<StudyDevices />} />}
        />
        <Route
          path={ROUTES.PARTICIPANT_DASHBOARD}
          element={<RouteWrapper element={<ParticipantDashboard />} />}
        />
      </Route>
      <Route
        path="*"
        element={
          <NotFound
            error={{
              statusCode: 404,
              message: "error.fallback.notfound.title",
            }}
          />
        }
      />
    </>
  )
);

function AppRoutes() {
  return (
    <AuthenticationProvider>
      <RouterProvider router={router} />
    </AuthenticationProvider>
  );
}

export default AppRoutes;
