import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomDrawer from "components/CustomDrawer";
import useGuideStore from "./guide.store";
import TroubleShootGuideItem from "./TroubleShootGuideItem.component";
import Search from "components/Search.component";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  TroubleshootGuideActions,
  TroubleshootGuideTypes,
} from "constants/index";
import { ROUTES } from "constants/routes.constants";
import { buildLocalUrl } from "utils/url.utils";

interface GuideProps {
  isOpen: boolean;
  onClose: () => void;
}

const TroubleShootGuide: React.FC<GuideProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { trialId, trialMemberId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const serialNumbers = searchParams.get("serial-numbers")?.split(",");
  const filteredGuides = useGuideStore((state) => state.filteredGuides);
  const setGuideSearchInput = useGuideStore((state) => state.setSearchInput);
  const setActiveGuideState = useGuideStore(
    (state) => state.setActiveGuideState
  );

  const handleSearch = (params: any) => {
    setGuideSearchInput(params.search);
  };

  const handleNavigateToDevicesScreen = async () => {
    if (!trialMemberId || !trialId) return;
    const redirectUrl = buildLocalUrl({
      path: ROUTES.TRIAL_PARTICIPANT_DEVICES,
      params: {
        trialId,
        trialMemberId,
      },
    });
    navigate(redirectUrl);
  };

  const handleNavigateToPurgeScreen = async () => {
    if (!serialNumbers || !trialId) return;
    const redirectUrl = buildLocalUrl({
      path: ROUTES.DEVICES_PURGE,
      params: {
        trialId,
        serialNumber: serialNumbers ? serialNumbers[0] : "",
      },
    });
    navigate(redirectUrl);
  };

  const handleNavigateToHomeScreen = async () => {
    const redirectUrl = buildLocalUrl({
      path: ROUTES.TRIALS_MANAGEMENT,
      params: {},
    });
    navigate(redirectUrl);
  };

  useEffect(() => {
    setActiveGuideState(TroubleshootGuideTypes.APPLICATION);
    if (trialId) {
      setActiveGuideState(TroubleshootGuideTypes.STUDY);
    }
  }, [trialId]);

  const actions = {
    [TroubleshootGuideActions.REDIRECT_TO_PURGE]: handleNavigateToPurgeScreen,
    [TroubleshootGuideActions.REDIRECT_TO_DEVICES]:
      handleNavigateToDevicesScreen,
    [TroubleshootGuideActions.REDIRECT_TO_HOME]: handleNavigateToHomeScreen,
    closeDrawer: onClose,
  };

  return (
    <CustomDrawer
      open={isOpen}
      onClose={onClose}
      additionalProps={{
        titleText: t("troubleShootGuide.header.title.label"),
        id: "troubleShootGuide-guide",
        headerWidth: 12,
      }}
    >
      <Grid
        container
        sx={{ padding: "1.5rem" }}
        flexDirection="column"
        gap={"2rem"}
      >
        <Grid item>
          <Search
            onSearch={handleSearch}
            placeholder={t("troubleShootGuide.search.placeholder")}
            triggerOnFieldChange={true}
            width="100%"
          />
        </Grid>
        <Grid item>
          {filteredGuides.length ? (
            filteredGuides.map((content, index) => (
              <React.Fragment key={content.id + index}>
                {content.guide.map((guide, guideIndex) => (
                  <TroubleShootGuideItem
                    key={`key_${guide.title}_${guideIndex}`}
                    guide={guide}
                    guideIndex={guideIndex}
                    actions={actions}
                  />
                ))}
              </React.Fragment>
            ))
          ) : (
            <Typography display={"flex"} justifyContent={"center"}>
              {t("troubleShootGuide.noRecordsFound.label")}
            </Typography>
          )}
        </Grid>
      </Grid>
    </CustomDrawer>
  );
};

export default TroubleShootGuide;
