import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  DeviceStateEnum,
  TroubleshootGuideTypes,
  containerStyle,
} from "constants/index";
import { ROUTES } from "constants/routes.constants";
import useTrialApi from "hooks/useTrialApi.hook";

import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import { useExperimentStore } from "stores/experiment.store";
import { DeviceAction, useDeviceStore } from "./device.store";
import useSnackbarStore from "serviceComponents/Snackbar/snackbar.store";

import ExperimentFormModal from "./Components/ExperimentFormModal";
import { buildLocalUrl } from "utils/url.utils";
import { statusIcon, getDeviceStatus } from "utils/device.utils";
import { CardStatus, getFiltersColor } from "components/Card/Card.component";
import QuickNavigation from "components/QuickNavigation/QuickNavigation.component";
import useDevicesApi from "hooks/useDevicesApi.hook";
import { rpcType } from "services/fleet.service";
import { ExperimentActions } from "pages/Experiment/experiment.dto";
import { StyledTitle } from "pages/Participants/Participants.styles";
import DevicesList from "./Components/DevicesList.component";
import Loading from "components/Loading";
import Search from "components/Search.component";
import useParticipantDashboardApi from "pages/ParticipantDashboard/participant.store";
import NotificationRibbon from "components/NotificationRibbon";
import useGuideStore from "components/TroubleShootGuide/guide.store";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";

const initDeviceStatus = [
  DeviceStateEnum.Ready,
  DeviceStateEnum.Preparing,
].join(",");

const Devices = () => {
  const { trialId, trialMemberId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const initHeader = useHeaderStore((state) => state.initialize);
  const setCurrentGuideId = useGuideStore((state) => state.setCurrentGuideId);
  const [status, setStatus] = useState<string>(initDeviceStatus);
  const [input, setInput] = useState<string>("");
  const [statusCount, setStatusCount] = useState<string>();

  const [searchSerialNumbers, setSearchSerialNumbers] = useState<string[]>([]);
  const deviceStatuses = useDeviceStore((state) => state.deviceStatuses);
  const setPrevStatus = useDeviceStore((state) => state.setPrevStatus);
  const prevStatus = useDeviceStore((state) => state.prevStatus);
  const openErrorNotification = useSnackbarStore((state) => state.openError);
  const queries = useTrialApi({ trialId: trialId || "", participants: false });
  const setInformationBlock = useInformationBlockStore((state) => state.init);

  const devicesQueries = useDevicesApi({
    serialNumbers: [],
  });
  const participantQueries = useParticipantDashboardApi({
    participantId: trialMemberId || "",
    trialId: trialId || "",
    fetchAnnotations: false,
  });

  const resetExperimentStore = useExperimentStore((state) => state.reset);
  const setSerialNumbers = useExperimentStore(
    (state) => state.setSerialNumbers
  );
  const setExperimentForm = useExperimentStore(
    (state) => state.setExperimentForm
  );
  const selectedSerialNumbers = useExperimentStore(
    (state) => state.serialNumbers
  );
  const setIsModalOpen = useDeviceStore(
    (state) => state.setIsExperimentModalOpen
  );
  const isModalOpen = useDeviceStore(
    (state) => state.isExperimentFormModalOpen
  );
  const setTrialDeviceRules = useDeviceStore(
    (state) => state.setTrialDeviceRules
  );
  const setDeviceContent = useDeviceStore((state) => state.setDeviceContent);
  const setSelectDeviceOptions = useDeviceStore(
    (state) => state.setSelectDeviceOptions
  );
  const setDeviceActions = useDeviceStore((state) => state.setDeviceActions);
  const setDeviceStatus = useDeviceStore((state) => state.setDeviceStatus);

  const [participant, setParticipants] = useState<any>({});
  const [deviceData, setDeviceData] = useState<any>([]);

  const devicePageStatus = useDeviceStore((state) => state.devicePageStatus);
  const setDevicePageStatus = useDeviceStore(
    (state) => state.setDevicePageStatus
  );
  const deviceContent = useDeviceStore((state) => state.deviceContent);

  const handleOpenExperimentFormModal = () => {
    setIsModalOpen(true);
  };

  const createRPCEvent = async (payload: any) => {
    const { params, status: validationStatus, sn, deviceData } = payload;

    if (
      validationStatus.includes(
        deviceData?.isDeviceReadyObj?.label?.toUpperCase()
      )
    ) {
      try {
        await devicesQueries.createRpcEvents.mutateAsync([
          {
            ...params,
            rpcType: rpcType.TWO,
            serialNumber: sn,
          },
        ]);
      } catch (err) {}
    }
  };

  const actions: ExperimentActions = {
    createRPCEvent: createRPCEvent,
  };

  const deviceStatusCheck = (device: any, serialNumber: string) => {
    if (!device) return;

    const rules = queries.trial.data?.configuration.data;

    const inUse = device?.annotation?.annotation.status;

    let deviceStatus = getDeviceStatus(inUse, rules, device);

    setDeviceStatus({ sn: serialNumber, state: deviceStatus.label });

    return {
      serialNumber,
      isDeviceRulePassed: deviceStatus.isDeviceRulePassed,
      deviceStatusObj: { sn: serialNumber, state: deviceStatus.label },
      isDeviceReadyObj: {
        label: deviceStatus.label,
        status: deviceStatus.status,
      },
      validationResults: deviceStatus.validationResults,
    };
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSerialNumbers([]);
  };

  useEffect(() => {
    resetExperimentStore();
  }, []);

  const handleFormSubmit = async (values: any) => {
    try {
      const { title, ...rest } = values;
      setExperimentForm({
        details: rest.comments,
        group: rest.group,
        pui: participant.identity,
        ...rest,
      });
      setExperimentForm({
        experimentForm: rest,
      });
      const url = buildLocalUrl({
        path: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
        params: {
          trialId,
          trialMemberId,
        },
        query: {
          "serial-numbers": selectedSerialNumbers?.join(","),
        },
      });
      handleCloseModal();
      navigate(url);
    } catch (error: any) {
      openErrorNotification(error.toString());
    }
  };

  useEffect(() => {
    if (!participantQueries.participant.data) return;
    const participant = participantQueries.participant.data;
    setParticipants(participant);
  }, [participantQueries.participant.isFetched]);

  useEffect(() => {
    if (!queries.trial.isFetched) return;
    setInformationBlock({ show: true });
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: queries.trial.data?.name || "",
        },
        subTitleItems: [
          {
            label: "header.title.subItem.label.participant",
            text: participant.pui || "",
          },
        ],
        showMenu: true,
      } as InitHeaderOptions,
      {
        activeStep: 2,
        allowAction: true,
        onClick: undefined,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipants",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIAL_PARTICIPANTS,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantDevices",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.TRIAL_PARTICIPANT_DEVICES,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantExperiment",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
            enableLink: false,
          },
          {
            label: "Results",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
        {
          key: "trialMemberId",
          value: trialMemberId || "",
        },
      ]
    );
    setCurrentGuideId(TroubleshootGuideTypes.DEVICES);
    const _annotationConfiguration = queries.trial.data?.configuration;
    if (!_annotationConfiguration) return;
    const trialDetails = _annotationConfiguration.data;

    setDeviceActions(trialDetails.device.actions as DeviceAction[]);
    setSelectDeviceOptions(trialDetails.options);
    setTrialDeviceRules(trialDetails.device.rules);
    setDeviceContent(trialDetails.device.content);
  }, [queries.trial.isFetched, participant]);

  useEffect(() => {
    const handleSearch = async () => {
      const serialNumbers = queries.trial.data?.serialNumbers || [];
      const results = serialNumbers.filter((sn: string) =>
        sn.toLowerCase().includes(input.toLowerCase())
      );
      results.length
        ? setSearchSerialNumbers(results)
        : setSearchSerialNumbers([]);

      const states = serialNumbers.reduce((counts: any, serialNumber: any) => {
        const state = deviceStatuses[serialNumber]?.state;
        if (state) {
          counts[state] = (counts[state] || 0) + 1;
        }
        return counts;
      }, {});

      setStatusCount(states);
    };

    handleSearch();
  }, [input, status, deviceStatuses]);

  useEffect(() => {
    const navigationOptionsObject = statusCount
      ? Object.entries(DeviceStateEnum)
          .filter(([key]) => key !== DeviceStateEnum.All)
          .map(([key, value]) => {
            const adjustedValue =
              value === DeviceStateEnum.Ready
                ? CardStatus.SUCCESS
                : value === DeviceStateEnum.Unavailable
                ? CardStatus.FAIL
                : value === DeviceStateEnum.InUse
                ? CardStatus.PENDING
                : value === DeviceStateEnum.Preparing
                ? CardStatus.WARNING
                : value;
            return {
              key: key,
              icon: statusIcon[value as DeviceStateEnum] as React.ElementType,
              color: getFiltersColor(adjustedValue as CardStatus, theme),
              value: value,
              totalCount: statusCount[value as any] || 0,
              defaultSelected: statusCount[value as any]
                ? status.includes(value)
                : false,
            };
          })
      : [];
    setNavigationOptions(navigationOptionsObject);
  }, [statusCount]);

  useEffect(() => {
    if (!queries.trial.data?.serialNumbers.length) return;
    const serialNumbers = queries.trial.data?.serialNumbers || [];
    if (!serialNumbers.length) setDevicePageStatus(CardStatus.FAIL);
    else setDevicePageStatus(undefined);

    devicesQueries.setOptions({ serialNumbers: serialNumbers });
  }, [queries.trial.isFetched]);

  const [navigationOptions, setNavigationOptions] = useState<any[]>();

  const handleClick = async (key: string) => {
    key = DeviceStateEnum[key as keyof typeof DeviceStateEnum];
    const statusArray = status.split(",");
    const isKeySelected = statusArray.includes(key);
    setStatus(
      isKeySelected
        ? statusArray.filter((status: any) => status !== key).join(",")
        : [...statusArray, key].join(",")
    );
    const updatedOptions = navigationOptions?.map((option) => ({
      ...option,
      defaultSelected:
        option.key === key ? !isKeySelected : option.defaultSelected,
    }));
    setNavigationOptions(updatedOptions);
  };

  const onSearch = (input: any) => {
    setInput(input.search);
  };

  useEffect(() => {
    if (!devicesQueries.devices.data?.length)
      setDevicePageStatus(CardStatus.FAIL);
    if (devicesQueries.devices.data?.length) {
      if (prevStatus !== devicesQueries.devices.data[0].status) {
        setPrevStatus(devicesQueries.devices.data[0].status);
      }
      setDeviceData(
        devicesQueries.devices.data.map((device) =>
          deviceStatusCheck(device, device.serialNumber)
        )
      );
    }
  }, [devicesQueries.devices.data, prevStatus]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (
    deviceContent &&
    [CardStatus.SUCCESS, CardStatus.PENDING].includes(
      devicePageStatus as CardStatus
    )
  ) {
    deviceContent.title = "trial.noze.devices.content.banner.title";
  }

  return (
    <>
      {deviceContent && devicesQueries.devices.isFetched ? (
        <NotificationRibbon
          status={devicePageStatus}
          configurations={deviceContent}
        />
      ) : null}
      <Grid display="flex" flexDirection={"column"} gap={2} sx={containerStyle}>
        <Grid item flexGrow={1}>
          <Grid container>
            <Grid item flexGrow={1}>
              <StyledTitle>{t("trialDevices.heading")}</StyledTitle>
            </Grid>
            <Grid item sx={{ flex: "1" }}>
              {!isMobile ? (
                <Search
                  onSearch={onSearch}
                  placeholder={"trialDevices.deviceSearch.label.search"}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexGrow={1}>
          <Grid container display={"flex"} sx={{ flexWrap: "nowrap" }}>
            {!isMobile ? (
              <Grid item sx={{ flex: "1" }}>
                <Typography>{t("trialDevices.subheading")}</Typography>
              </Grid>
            ) : null}
            <Grid item sx={{ flex: "1" }}>
              {isMobile ? (
                <Search
                  onSearch={onSearch}
                  placeholder={"trialDevices.deviceSearch.label.search"}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container display="flex" gap={2}>
          {navigationOptions?.length &&
            navigationOptions.map((option) => (
              <Grid item key={option.key}>
                <QuickNavigation
                  text={option.value}
                  icon={option.icon}
                  color={option.color}
                  count={option.totalCount}
                  defaultSelected={option.defaultSelected}
                  onClick={() =>
                    option.totalCount ? handleClick(option.key) : {}
                  }
                />
              </Grid>
            ))}
        </Grid>
        <Grid
          item
          flexGrow={1}
          sx={{ fontFamily: theme.typography.fontFamily }}
        >
          {devicesQueries.devices.data ? (
            <DevicesList
              devices={devicesQueries.devices.data || []}
              deviceData={deviceData}
              status={status}
              actions={actions}
              input={input}
              showPrechecks={true}
              searchSerialNumbers={searchSerialNumbers}
              handleOpenExperimentFormModal={handleOpenExperimentFormModal}
            />
          ) : (
            <Loading height="50vh" />
          )}
        </Grid>
        <Grid container sx={{ marginTop: "1rem" }} gap={2}>
          {isModalOpen && (
            <ExperimentFormModal
              isModalOpen={isModalOpen}
              onSubmit={handleFormSubmit}
              onClose={handleCloseModal}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Devices;
