import { useEffect, useState } from "react";
import {
  useHeaderStore,
  ProgressBarStatus,
  InitProgressBarOptions,
  InitHeaderOptions,
} from "stores/header.store";
import { ROUTES } from "constants/routes.constants";
import useTrialApi from "hooks/useTrialApi.hook";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Button, { ButtonColor } from "components/Button";
import ParticipantsList from "./Components/ParticipantsList.component";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { StyledTitle } from "./Participants.styles";
import { participantPerPage, containerStyle, SortOrder, TroubleshootGuideTypes } from "constants/index";
import useTrialCardApi from "features/trials/TrialCards/useTrialCardApi.hook";
import NewParticipant from "features/trials/components/createTrial/NewParticipant";
import Search from "components/Search.component";
import { useInformationBlockStore } from "components/InformationBlock/informationblock.store";
import { useLeftNavigationStore } from "stores/navigation.store";
import { useHelpers } from "./usehelpers";
import { TRIAL_ANALYTICS_EXPAND } from "services/cde.service";
import useGuideStore from "components/TroubleShootGuide/guide.store";
const Participants = () => {
  const { t } = useTranslation();
  const { trialId } = useParams();
  const theme = useTheme();
  const { deleteParticipant } = useTrialCardApi({ trialId: trialId || "" });
  const initHeader = useHeaderStore((state) => state.initialize);
  const [openNewParticipant, setOpenNewParticipant] = useState(false);
  const setInformationBlock = useInformationBlockStore((state) => state.init);
  const { switchNavigationState } = useLeftNavigationStore((state) => state);
  const setCurrentGuideId = useGuideStore((state) => state.setCurrentGuideId);

  const queries = useTrialApi({
    trialId: trialId || "",
    take: participantPerPage,
    skip: 0,
    participants: true,
    expand: `${TRIAL_ANALYTICS_EXPAND.ANNOTATIONS}|`,
    order: `createdAt|${SortOrder.DESC}`,
  });

  useEffect(() => {
    setInformationBlock({ show: false });
    switchNavigationState("default");
    initHeader(
      {
        mainTitle: {
          label: "header.title.main.label.study",
          text: queries.trial.data?.name || "",
        },
        subTitleItems: [],
        showMenu: true,
      } as InitHeaderOptions,
      {
        activeStep: 1,
        allowAction: true,
        onClick: undefined,
        steps: [
          {
            label: "progressBar.step.trialsManagement",
            status: ProgressBarStatus.SUCCESS,
            link: ROUTES.TRIALS_MANAGEMENT,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipants",
            status: ProgressBarStatus.IN_PROGRESS,
            link: ROUTES.TRIAL_PARTICIPANTS,
            enableLink: true,
          },
          {
            label: "progressBar.step.trialParticipantDevices",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_DEVICES,
            enableLink: false,
          },
          {
            label: "progressBar.step.trialParticipantExperiment",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT,
            enableLink: false,
          },
          {
            label: "Results",
            status: ProgressBarStatus.PENDING,
            link: ROUTES.TRIAL_PARTICIPANT_EXPERIMENT_RESULTS,
            enableLink: false,
          },
        ],
      } as InitProgressBarOptions,
      [
        {
          key: "trialId",
          value: trialId || "",
        },
        {
          key: "trialMemberId",
          value: "",
        },
      ]
    );
    setCurrentGuideId(TroubleshootGuideTypes.PARTICIPANTS);
  }, [queries.trial.isFetched]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const onSubmit = (params: any) => {
    const options = {
      ...queries.options,
      ...{ pui: params.search },
      skip: 0,
    };
    queries.setOptions(options);
  };

  const handleClickOpen = () => {
    setOpenNewParticipant(true);
  };
  const handleClose = async () => {
    setOpenNewParticipant(false);
  };

  const { createNewParticipant, handleSorting } = useHelpers(
    queries,
    trialId || "",
    handleClose
  );

  const handleDeleteParticipant = async (participant: any) => {
    const uuid = participant.uuid;
    deleteParticipant.mutate(uuid);
    queries.refreshTrial();
  };

  return (
    <>
      <Grid display="flex" flexDirection={"column"} gap={2} sx={containerStyle}>
        <Grid item flexGrow={1}>
          <Grid container>
            <Grid item flexGrow={1}>
              <StyledTitle>{t("trialParticipant.heading")}</StyledTitle>
            </Grid>
            <Grid item>
              <Button onClick={handleClickOpen} color={ButtonColor.primary}>
                {t("new_participant")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexGrow={1}>
          <Grid container display={"flex"} sx={{ flexWrap: "nowrap" }}>
            {!isMobile ? (
              <Grid item sx={{ flex: "1" }}>
                <Typography>{t("trialParticipant.subheading")}</Typography>
              </Grid>
            ) : null}
            <Grid item sx={{ flex: "1" }}>
              <Search
                onSearch={onSubmit}
                placeholder={"trialParticipant.participantSearch.label.search"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          flexGrow={1}
          sx={{ fontFamily: theme.typography.fontFamily }}
        >
          <ParticipantsList
            queries={queries}
            handleDeleteParticipant={handleDeleteParticipant}
            handleSorting={handleSorting}
          />
        </Grid>
        {openNewParticipant && queries.trial.isFetched ? (
          <NewParticipant
            isOpen={openNewParticipant}
            trial={queries.trial.data}
            onClose={handleClose}
            onSubmit={createNewParticipant}
          />
        ) : null}
      </Grid>
    </>
  );
};

export default Participants;
