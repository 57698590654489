export type ResultActions =
  | "navigatePurge"
  | "navigateDevices"
  | "navigateDevicesViaPurge"
  | "navigateProgressBar"
  | "navigateProgressBarViaPurge"
  | "navigateExit"
  | "navigateExitViaPurge";

export const timeFormat = {
  UTC: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
};
export interface BreadcrumbItemType {
  label: string;
}

export enum ParticipantType {
  PUI = "PUI",
}

export enum AnnotationType {
  ANNOTATION = "ANNOTATION",
  TRIAL = "TRIAL",
}

export enum IdentityType {
  PARTICIPANT = "PARTICIPANT",
  TRIAL = "TRIAL",
}

export enum EvaluationStatus {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
}
export enum DeviceStatusEnum {
  OFFLINE = "OFFLINE",
  ONLINE = "ONLINE",
  CONNECTION_LOST = "CONNECTION_LOST",
  ERROR = "ERROR",
}

export enum DeviceStateEnum {
  All = "All",
  Ready = "Ready",
  Preparing = "Checking",
  Unavailable = "Unavailable",
  InUse = "InUse",
}

export enum AnnotationStatus {
  PENDING = "PENDING",
  TERMINATED = "TERMINATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  TIMEOUT = "TIMEOUT",
}

export enum AnnotationEventType {
  TERMINATE = "Terminate",
}

export enum DeviceReadyStatus {
  PENDING = "PENDING",
  WARNING = "WARNING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  INUSE = "INUSE",
}

export interface BreadcrumbsDataType {
  [key: string]: BreadcrumbItemType;
}

export const itemsPerPage = 4;
export const participantPerPage = 10;
export const devicePerPage = 10;
export const annotationPerPage = 24;
export const studiesDashboardRecordPerPage = 10;

export const breadcrumbsData: BreadcrumbsDataType = {
  trialManagement: { label: "Trial Management" },
  trialDevices: { label: "Trial Devices" },
  experiment: { label: "Experiment" },
};
export const iconsLink = {
  exit_sample: "icons/exit_sample.svg",
  back: "icons/previous.svg",
  studyOverview: "icons/overview.svg",
  studyParticipants: "icons/participants.svg",
  studyDevices: "icons/devices.svg",
};

interface TrialNew {
  [key: string]: {
    defaultSelected: boolean;
    text: string;
    icon: string;
    count: number;
  };
}

export const trialStatusConfig: TrialNew = {
  PENDING: {
    defaultSelected: true,
    text: "trial.status.pending",
    icon: "PendingIcon",
    count: 0,
  },
  COMPLETE: {
    defaultSelected: true,
    text: "trial.status.complete",
    icon: "CompletedIcon",
    count: 0,
  },
  TERMINATED: {
    defaultSelected: true,
    text: "trial.status.terminated",
    icon: "TerminateIcon",
    count: 0,
  },
  IN_PROGRESS: {
    defaultSelected: true,
    text: "trial.status.inProgress",
    icon: "ProgressIconPie",
    count: 0,
  },
};

export enum ProgressBarStyle {
  BREADCRUMB = "BREADCRUMB",
  LINEAR_PROGRESS = "LINEAR_PROGRESS",
}

export enum CardOrientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export enum TimerDirection {
  UP = "UP",
  DOWN = "DOWN",
}
export const maxPageWidth = 1280;

export const containerStyle = {
  maxWidth: maxPageWidth,
  width: "100%",
  margin: "0 auto",
  paddingLeft: "0.5rem",
  padding: "1rem",
};

export enum ErrorStatus {
  TIMEOUT = "TIMEOUT",
  TERMINATED = "TERMINATED",
}

export enum SearchType {
  DATE = "DATE",
  TEXT = "TEXT",
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum PermissionTypes {
  VIEW_DASHBOARDS = "VIEW:DASHBOARDS",
  PARTICIPANT = "PARTICIPANT",
  MEMBER = "MEMBER",
  ADMIN = "ADMIN",
  OWNER = "OWNER",
}

export enum graphType {
  BAR = "bar",
  LINE = "line",
  PIE = "pie",
  SCATTER = "scatter",
  EMPTY = "empty",
}

export enum LeftNavigationItemsOrientation {
  "TOP" = "top",
  "BOTTOM" = "bottom",
}

export enum LanguagesLabel {
  "en" = "English",
  "fr" = "Français",
}

export enum LeftNavigationMenuItems {
  TRIALMANAGEMENT = "trialManagement",
  STUDIESDASHBOARD = "studiesDashboard",
  SETTINGS = "settings",
  LOGOUT = "logout",
}

export const ExperimentDeviceStatusQueryTimeout = 2000;

export enum TroubleshootGuideMediaTypes {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum TroubleshootGuideTypes {
  APPLICATION = "application",
  STUDY = "study",
  TRIALMANAGEMENT = "trialManagement",
  DEVICES = "devices",
  PURGE = "purge",
  EXPERIMENT = "experiment",
  RECOVERY = "recovery",
  PARTICIPANTS = "participants",
  RESULTS = "results",
}

export enum TroubleshootGuideContextTag {
  DEVICE_STATUS = "device_status",
  VALIDATOR = "validator",
  DEVICE = "device",
  MAX_CONSECUTIVE_DROPPED_PAYLOAD = "validations.MAX_CONSECUTIVE_DROPPED_PAYLOAD",
}

export enum TroubleshootGuideActions {
  REDIRECT_TO_PURGE = "redirectToPurge",
  REDIRECT_TO_DEVICES = "redirectToDevices",
  REDIRECT_TO_HOME = "redirectToHome",
}
