import { useEffect } from "react";
import { Grid } from "@mui/material";
import useDeviceApi from "hooks/useDeviceApi.hook";
import { ExperimentDeviceStatusQueryTimeout, TroubleshootGuideContextTag } from "constants/index";
import { CardStatus, getCardColor } from "components/Card";
import { useExperimentStore } from "stores/experiment.store";
import { useTheme } from "@mui/system";
import { CardOrientation } from "constants/index";
import { DeviceValidate, IndicatorType } from "services/annotation.service";
import GaugeLinearController from "./GaugeLinear.controller";
import MetricComponent from "components/MetricsContainer/Metric.component";

export const MetricsContainer = () => {
  const theme = useTheme();
  const { validate, currentEvent, serialNumbers, validation } =
    useExperimentStore((state) => ({
      validate: state.validate,
      currentEvent: state.currentEvent,
      serialNumbers: state.serialNumbers,
      validation: state.validation,
    }));
  const setFailedStep = useExperimentStore((state) => state.setFailedStep);
  const deviceQueries = useDeviceApi({
    serialNumber: serialNumbers?.[0] || "",
    refetchInterval: ExperimentDeviceStatusQueryTimeout,
  });

  useEffect(() => {
    if (deviceQueries.device.isError) {
      if (!currentEvent) return;
      validate([]);
      setFailedStep(currentEvent?.name);
      return;
    }
  }, [deviceQueries.device.isError]);

  useEffect(() => {
    if (!deviceQueries) return;
    if (!deviceQueries.device.data?.state.metrics) return;
    const metrics = deviceQueries.device.data.state.metrics;
    validate(metrics);
  }, [deviceQueries.device.data, validate]);

  if (!deviceQueries.device.data) return null;
  if (!validation) return null;
  if (!validation.results) return null;
  if (!currentEvent) return null;
  if (!currentEvent.validate) return null;

  const getStatus = (title: string) => {
    const result = validation?.results?.find((item) => item.name === title);
    return result
      ? result.verdict
        ? CardStatus.SUCCESS
        : CardStatus.WARNING
      : CardStatus.SUCCESS;
  };

  const orientation =
    currentEvent?.validate?.orientation || CardOrientation.HORIZONTAL;
  return (
    <Grid
      container
      spacing={"1.5rem"}
      alignItems={
        CardOrientation.VERTICAL === orientation ? "flex-start" : "center"
      }
      direction={CardOrientation.VERTICAL === orientation ? "column" : "row"}
    >
      {currentEvent?.validate.rules.map((item: DeviceValidate) => {
        const isVisible = item?.isVisible ?? true;
        const status = !isVisible ? CardStatus.SUCCESS : getStatus(item.title);
        const cardColor = getCardColor(status, theme);

        return !item.type || item?.type === IndicatorType.INDICATOR ? (
          <MetricComponent
            key={item.title}
            label={item.title}
            color={cardColor.main}
            status={status}
            sx={isVisible ? { display: "flex" } : { display: "none" }}
            guideMetaData={{
              show: status !== CardStatus.SUCCESS,
              contextTag: [
                ...item?.params,
                TroubleshootGuideContextTag.VALIDATOR,
              ],
            }}
          />
        ) : (
          <Grid item key={item.title} width={"100%"}>
            <GaugeLinearController metric={item} />
          </Grid>
        );
      })}
    </Grid>
  );
};
